import Footer from "@/components/Ui/Shared/Footer/Footer";
import Header from "@/components/Ui/Shared/Header/Header";
import styled from "styled-components";

interface Props {
  children: Readonly<React.ReactNode>;
}

export default function Layout({ children }: Props) {
  return (
    <Wrapper>
      <Header />
      {children}
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
