import { shared } from "@/services/requests/requests"
import { useQuery } from "react-query"

interface Props {
    type: "landing" | string
}

function useStoriesSlider({ type }: Props) {

    const { data, isLoading } = useQuery(['BRAND_SLIDER', type], () => shared.getStoriesSlider({
        category: type
    }))

    return {
        data,
        isLoading
    }

}

export default useStoriesSlider