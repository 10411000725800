import { MEDIA_URL } from "@/constant";
import useBrandSlider from "@/hooks/shared/useStoriesSlider";
import Link from "next/link";
import React, { useId } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface Props {
  type: "landing" | number;
}

function BrandList({ type }: Props) {
  const { data } = useBrandSlider({
    type: String(type),
  });

  const brandListKey = useId();

  return (
    <div className="brand-container-mobile">
      <Swiper
        modules={[Navigation]}
        id="brand-carousel"
        spaceBetween={12}
        slidesPerView={6}
        navigation
        breakpoints={{
          200: {
            slidesPerView: 3,
            spaceBetween: 12,
          },
          320: {
            slidesPerView: 5,
            spaceBetween: 12,
          },
          400: {
            slidesPerView: 5,
            spaceBetween: 12,
          },
          450: {
            slidesPerView: 6,
            spaceBetween: 12,
          },
          550: {
            slidesPerView: 7,
            spaceBetween: 12,
          },
          600: {
            slidesPerView: 8,
            spaceBetween: 12,
          },
          768: {
            slidesPerView: 9,
            spaceBetween: 12,
          },
          800: {
            slidesPerView: 9,
            spaceBetween: 12,
          },
          900: {
            slidesPerView: 10,
            spaceBetween: 12,
          },
          1100: {
            slidesPerView: 11,
            spaceBetween: 12,
          },
        }}
      >
        {data?.data.map((item, index) => (
          <SwiperSlide key={brandListKey + index}>
            <Link href={item.url} className="image-container">
              <img
                src={MEDIA_URL + "/stories/" + item.thumb_nail}
                alt={item.title}
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default BrandList;
